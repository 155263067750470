<template>
  <el-dialog title="选择文章" :visible.sync="openDialog" width="60%" :show-close="false" class="dialog-vertical"
    @close="cancel" append-to-body>
    <div class="head-filter">
      <el-input size="medium" v-model="filterForm.keyword" placeholder="请输入名称" @change="handleFilter">
        <i class="el-icon-search el-input__icon" slot="suffix"></i>
      </el-input>
    </div>
    <div class="member-list">
      <el-table :data="SelectList" style="width: 100%" :height="250" ref="multipleTable"
        @row-click="handleCurrentChange" v-loading="loading" v-loadmore="LoadMore">

        <!-- 单选 -->
        <el-table-column label="操作" fixed="left" width="70" :key="Math.random()">
          <template slot-scope="scope">
            <el-radio :label="scope.row.id" v-model="article_id"><span></span></el-radio>
          </template>
        </el-table-column>
        <template v-for="(th, index) in thead">
          <el-table-column show-overflow-tooltip :prop="th.prop" :label="th.label"
            :min-width="th.minWidth || ''" :key="index">
            <template slot-scope="scope">
              <span>{{ scope.row[th.prop] | placeholder }}</span>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </div>
    <span slot="footer">
      <el-button type="primary" @click="submit" :loading="saveLoading">确定</el-button>
      <el-button @click="openDialog = false">取消</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { dataList, sourceOption } from "../../api/news-play/index";
import debounce from 'lodash/debounce'
export default {
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    articleID: {
      type: [Number, String],
      default: "",
    },
  },
  data() {
    return {
      loading: false, //加载
      thead: [
        { label: "标题", prop: "title", minWidth: 150 },
        { label: "来源", prop: "source_name", minWidth: 110 },
        { label: "发布时间", prop: "post_time", minWidth: 160 },
      ],
      SelectList: [], // 活动/文章列表
      getable: true, // 是否还可以获取列表
      //筛选对象
      filterForm: {
        component: "st-article-list",
        keyword: "", //关键词
        page_size: 10, //每页多少条数据
        page: 1,
      },
      sourceOptionList: [],
      article_id: "", //勾选的文章id
      saveLoading: false,
    };
  },
  methods: {
    // 获取活动/文章列表(下拉刷新防抖动优化)
    getPostedList: debounce(function () {
      if (!this.getable) return;
      this.loading = true;
      const pageData = { ...this.filterForm };
      pageData.source_id = pageData.source_id === "" ? -1 : pageData.source_id;
      dataList(pageData)
        .then((res) => {
          const { data } = res;
          if (pageData.page === 1) {
            this.SelectList = data.list.data;
          } else {
            this.SelectList = [...this.SelectList, ...data.list.data];
          }
          if (data.list.data.length < 10) this.getable = false;
          this.filterForm.page++;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    }, 200),
    // 获取来源列表
    getsourceOptionList() {
      sourceOption({ portal_user_id: 0 })
        .then((res) => {
          this.sourceOptionList = res.data;
        })
        .catch((err) => {});
    },
    // 获取架构列表
    getParentToList() {
      parentToList({ crr_level: -1, is_show_all: 1 })
        .then((res) => {
          const { data } = res;
          data.map((item) => {
            item.children = [];
          });
          this.OrganizationList = data;
        })
        .catch((err) => {});
    },
    // 点击数据项操作
    handleCurrentChange(val) {
      this.article_id = val.id;
    },
    // 筛选操作
    handleFilter() {
      this.getable = true;
      this.filterForm.page = 1;
      this.getPostedList();
    },
    //触底刷新
    LoadMore() {
      this.getPostedList();
    },
    // 确定
    submit() {
      this.$emit("chooseArticle", {
        article_id: this.article_id,
        title: this.SelectList.find((el) => el.id === this.article_id).title,
      });
      this.openDialog = false;
    },
    // 清除弹窗数据
    clearDialog() {
      this.$refs.multipleTable.clearSelection();
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
      this.filterForm.page = 1;
      this.getable = true;
      this.getPostedList();
      this.saveLoading = false;
      this.openDialog = false;
    },
    // 取消
    cancel(formName) {
      this.openDialog = false;
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0;
    },
  },
  watch: {
    openDialog(val) {
      if (val) {
        this.article_id = this.articleID;
        this.getable = true;
        this.filterForm.keyword = "";
        this.filterForm.page = 1;
        this.getPostedList();
        this.getsourceOptionList();
      }
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.check-post {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.head-filter {
  width: 60%;
  display: flex;
  position: absolute;
  justify-content: flex-end;
  top: 20px;
  right: 20px;

  .el-select,
  .el-cascader,
  .el-input {
    width: 50%;
    margin-right: 10px;
  }
}

.member-list {
  ::v-deep.el-table__row {
    cursor: pointer;
  }
}
</style>
