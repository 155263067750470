<!--
 * @Author: dongjia
 * @Date: 2021-09-16 17:05:19
 * @LastEditTime: 2021-10-13 14:15:12
 * @LastEditors: aleaner
 * @Description: 新闻联播新增编辑弹窗
 * @FilePath: \saas-admin-vue\src\modules\gov\components\news-play\AddNewsDialog.vue
 * 怎么可能会有bug！！！
-->
<template>
  <el-dialog :title="`${Data.article_id?'编辑':'新增'}联播`" :visible.sync="openDialog" width="600px"
    class="dialog-vertical" append-to-body>
    <el-form size="medium" :model="form" :rules="rules" label-position="right" label-width="90px" ref="form" @submit.native.prevent>
      <el-form-item label="选择文章：" prop="article_id">
        <el-button class="dialog-btn" @click="openArticleSelector = true">
          {{form.article_id?form.title:'请选择文章'}}</el-button>
        <el-input v-show="false" v-model="form.article_id"></el-input>
      </el-form-item>
      <el-form-item label="选择时间：" required>
        <div style="display: flex">
          <el-form-item label="" label-width="0px" prop="start_time">
            <el-date-picker style="width: 200px" v-model="form.start_time"
              :picker-options="startPickerOptions" type="datetime" placeholder="选择开始时间">
            </el-date-picker>
          </el-form-item>
          <span style="margin: 0 10px">至</span>
          <el-form-item label="" label-width="0px" prop="end_time">
            <el-date-picker style="width: 200px" v-model="form.end_time" :picker-options="endPickerOptions"
              type="datetime" placeholder="选择结束时间">
            </el-date-picker>
          </el-form-item>
        </div>
      </el-form-item>
    </el-form>
    <ArticleSelector v-model="openArticleSelector" :articleID="form.article_id"
      @chooseArticle="handleArticleChoose" />
    <span slot="footer">
      <el-button size="small" @click="openDialog = false">取消</el-button>
      <el-button size="small" type="primary" @click="submit('form')" :loading="saveLoading">保存</el-button>
    </span>
  </el-dialog>
</template>

<script>
import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";
import ArticleSelector from "./ArticleSelector";
export default {
  name: "AddLiveDialog",
  components: { DatePeriodPicker, ArticleSelector },
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    Data: {
      type: Object,
      default: () => {
        return {
          id: 0,
          title: "",
          article_id: "",
          start_time: "",
          end_time: "",
        };
      },
    },
  },
  data() {
    return {
      saveLoading: false,
      form: {
        id: 0,
        article_id: 0,
        title: "",
        start_time: "",
        end_time: "",
      },
      rules: {
        article_id: [
          { required: true, message: "请选择文章", trigger: "change" },
        ],
        start_time: [
          { required: true, message: "请选择开始时间", trigger: "change" },
        ],
        end_time: [
          { required: true, message: "请选择结束时间", trigger: "change" },
        ],
      },
      openArticleSelector: false,
      // 活动开始时间选择范围
      startPickerOptions: {
        disabledDate: (time) => {
          return this.selectableDate(time, 0);
        },
      },
      // 活动结束时间选择范围
      endPickerOptions: {
        disabledDate: (time) => {
          return this.selectableDate(time, 1);
        },
      },
    };
  },
  watch: {
    openDialog(val) {
      if (val) {
        this.$nextTick(() => {
          this.form = { ...this.form, ...this.Data };
        });
      } else {
        this.$refs.form.resetFields();
      }
    },
  },
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(value) {
        this.$emit("closeDialog", value);
      },
    },
  },
  methods: {
    // 设置开始时间选择范围
    selectableDate(time, type) {
      if (type) {
        return this.form.start_time
          ? time.getTime() < this.form.start_time
          : false;
      } else {
        return this.form.end_time ? time.getTime() > this.form.end_time : false;
      }
    },
    // 选择文章操作
    handleArticleChoose(val) {
      this.form = { ...this.form, ...val };
    },
    // 保存
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-vertical {
  ::v-deep.el-input__suffix {
    right: 0px;
  }

  ::v-deep.el-scrollbar__wrap {
    overflow-x: hidden;
  }

  ::v-deep.el-scrollbar__view {
    &:last-child {
      margin-bottom: 20px;
    }
  }
}

.scroll-view {
  height: 300px;
  width: 570px;
  margin: 0 auto;
}

.search-slot {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 100%;
  color: #fff;
  background-color: #3576ff;
  cursor: pointer;
}

.main-item {
  margin: 12px auto 0;
  box-shadow: 0 0 10px #eeeeee;
  width: 557px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .item-left {
    display: flex;
    align-items: center;
    .list-image {
      width: 78px;
      height: 64px;
    }
    .item-content {
      margin-left: 16px;
      .content-top {
        line-height: 20px;
      }
      .content-bottom {
        margin-top: 10px;
        width: 280px;
        @include nowrap();
        line-height: 20px;
      }
    }
  }
}

.dialog-btn {
  max-width: 300px;
  @include nowrap();
}
</style>