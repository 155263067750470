/*
 * @Author: dongjia
 * @Date: 2021-09-16 16:45:50
 * @LastEditTime: 2021-09-24 16:31:02
 * @LastEditors: aleaner
 * @Description: 新闻联播接口
 * @FilePath: \gov\api\news-play\index.js
 * 怎么可能会有bug！！！
 */
import api from "@/base/utils/request";


//新闻列表
export const newsList = data => {
  return api({
    url: "/cyc/admin/gov_news/index",
    method: "post",
    data,
    mock: true
  })
}

// 文章来源选项列表
export const sourceOption = data => {
  return api({
    url: "/admin/portal/source/all",
    method: "post",
    data
  })
}

// 文章分类选项数据
export const categoryOption = data => {
  return api({
    url: "/admin/portal/category/all",
    method: "post",
    data
  })
}

// 获取文章列表
export const dataList = data => {
  return api({
    url: "/admin/index/dialog/index",
    method: "post",
    data
  });
};
