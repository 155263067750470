<!--
 * @Author: dongjia
 * @Date: 2021-09-16 16:27:34
 * @LastEditTime: 2021-09-17 11:40:57
 * @LastEditors: aleaner
 * @Description: 新闻联播列表页
 * @FilePath: \gov\views\news-play\index.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="list-page">
    <el-button class="top-btn" type="primary" size="small" @click="handleAddNews">新增联播
    </el-button>
    <list-filter :filterForm="filterForm" :uploadFilter="ok" />
    <list-tabs v-model="filterForm.tab" :tabs="tabArr" :isLoading="loading" @tab-click="handleClick">
    </list-tabs>
    <div class="list-content" v-loading="loading">
      <div class="list-btn" v-if="filterForm.tab === 'normal'">
        <el-button type="danger" size="small" @click="handleDel">删除</el-button>
      </div>
      <div class="list-btn" v-if="filterForm.tab === 'deleted'">
        <el-button type="success" size="small" @click="handleReduct">还原</el-button>
        <el-button type="danger" size="small" @click="handleDel">删除</el-button>
      </div>
      <el-table :data="tbody" class="thead-light" stripe style="width: 100%"
        @selection-change="handleSelectionChange" @sort-change="sortChange">
        <!-- 勾选-->
        <el-table-column fixed type="selection" width="55"></el-table-column>
        <!-- 操作 -->
        <el-table-column label="操作" width="130" fixed="right">
          <template slot-scope="scope">
            <el-button-group v-if="filterForm.tab == 'deleted'">
              <el-button size="small" @click="ReductRow(scope.row)" type="text">
                还原
              </el-button>
              <el-button size="small" @click="deleteRow(scope.row)" type="text">删除</el-button>
            </el-button-group>
            <el-button-group v-if="filterForm.tab == 'normal'">
              <el-button size="small" @click="editRow(scope.row)" type="text">
                编辑
              </el-button>
              <el-button size="small" @click="deleteRow(scope.row)" type="text">删除</el-button>
            </el-button-group>
          </template>
        </el-table-column>
        <template v-for="(th, index) in thead">
          <el-table-column show-overflow-tooltip :prop="th.prop" :label="th.label"
            :min-width="th.minWidth || ''" :key="index" :sortable="th.sortable">
            <template slot-scope="scope">
              <el-checkbox v-if="['is_show'].includes(th.prop)" :false-label="0" :true-label="1"
                v-model="tbody[scope.$index][th.prop]" @change="changeStatus(scope.row)">
              </el-checkbox>
              <!-- 普通 -->
              <span v-else>{{ scope.row[th.prop] | placeholder }}</span>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </div>
    <Pagination :total="pageData.total" :page="pageData.current_page" :change="changPage" />
    <AddNewsDialog v-model="openAddNews" :Data="editForm" :updateList="refreshList" />
  </div>
</template>

<script>
import listFilter from "../../components/news-play/ListFilter";
import Pagination from "@/base/components/Default/Pagination";
import {
  newsList,
  // updateShow,
  // softDelete,
  // Delete,
  // putBack,
} from "../../api/news-play/index";
import AddNewsDialog from "../../components/news-play/AddNewsDialog";
export default {
  data() {
    return {
      tabArr: [
        { label: "全部", name: "normal" },
        { label: "回收站", name: "deleted" },
      ],
      loading: false, //加载
      thead: [
        { label: "ID", prop: "id", minWidth: 50 },
        { label: "文章标题", prop: "title", minWidth: 150 },
        { label: "来源", prop: "source_name", minWidth: 110 },
        { label: "分类", prop: "category_name", minWidth: 110 },
        { label: "作者", prop: "author_name", minWidth: 130 },
        {
          label: "联播时间",
          prop: "create_time",
          minWidth: 160,
          sortable: true,
        },
        {
          label: "状态",
          prop: "status_text",
          minWidth: 110,
        },
        {
          label: "显示",
          prop: "is_show",
          minWidth: 80,
        },
      ],
      //筛选对象
      filterForm: {
        start_time: -1, //开始时间
        end_time: -1, //结束时间
        keyword: "", //文章标题
        category_id: -1, // 文章分类
        source_id: -1, // 文章来源
        tab: "normal", //当前筛选tab
        page_size: 15,
      },
      // 筛选缓存
      filters: {
        normal: null,
        deleted: null,
      },
      pageData: {
        page_size: 15,
        tab: "normal",
      },
      tbody: [],
      selectArr: [], //勾选的数组id
      /* 行内编辑数字验证 */
      validator: {
        type: "integer",
        min: 0,
        message: "最小不能低于0的整数",
        trigger: "blur",
      },
      editForm: {
        id: 0,
        article_id: 0,
        title: "",
        start_time: "",
        end_time: "",
      },
      openAddNews: false,
    };
  },
  methods: {
    // 获取品牌列表
    getNewsList(pageData) {
      this.tbody = [];
      this.pageData = {};
      this.loading = true;
      newsList(pageData)
        .then((res) => {
          const { data } = res;
          this.tbody = data.data;
          this.pageData = data;
          this.filters[data.tab] = { ...this.filterForm };
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 勾选操作
    handleSelectionChange(selection) {
      if (!selection.length) {
        this.selectArr = [];
      } else {
        this.selectArr = [];
        // 筛选id
        selection.forEach((item) => {
          this.selectArr.push(item.id);
        });
      }
    },
    // 新增联播
    handleAddNews() {
      this.editForm = {
        id: 0,
        article_id: 0,
        title: "",
        start_time: "",
        end_time: "",
      };
      this.openAddNews = true;
    },
    // 批量删除
    handleDel() {
      if (this.selectArr.length) {
        if (this.filterForm.tab === "normal") {
          this.$msgbox
            .confirm("确定要将选中数据移入回收站吗？", "提示", {
              type: "info",
            })
            .then((res) => {
              this.handleDelete({ id: this.selectArr });
            })
            .catch((err) => {});
        } else {
          this.$msgbox
            .confirm("确定要将选中数据永久删除吗？", "提示", {
              type: "error",
            })
            .then((res) => {
              this.handleDelete({ id: this.selectArr });
            })
            .catch((err) => {});
        }
      } else {
        this.$message.info("请选择数据项");
      }
    },
    // 点击列表删除
    deleteRow(row) {
      if (this.filterForm.tab === "normal") {
        this.$msgbox
          .confirm("确定要将此数据移入回收站吗？", "提示", {
            type: "info",
          })
          .then((res) => {
            this.handleDelete({ id: row.id });
          })
          .catch((err) => {});
      } else {
        this.$msgbox
          .confirm("确定要将此数据永久删除吗？", "提示", {
            type: "error",
          })
          .then((res) => {
            this.handleDelete({ id: row.id });
          })
          .catch((err) => {});
      }
    },
    // 数据删除
    handleDelete(id) {
      this.loading = true;
      if (this.filterForm.tab === "normal") {
        softDelete(id)
          .then((res) => {
            this.$message.success(res.msg);
            this.refreshList();
          })
          .catch((err) => {
            this.loading = false;
          });
      } else {
        Delete(id)
          .then((res) => {
            this.$message.success(res.msg);
            this.refreshList();
          })
          .catch((err) => {
            this.loading = false;
          });
      }
    },
    // 批量还原数据
    handleReduct() {
      if (this.selectArr.length) {
        this.$msgbox
          .confirm("确定要将选中数据还原吗？", "提示", {
            type: "info",
          })
          .then((res) => {
            this.Reduct({ id: this.selectArr });
          })
          .catch((err) => {});
      } else {
        this.$message.info("请选择数据项");
      }
    },
    // 点击列表还原
    ReductRow(row) {
      this.$msgbox
        .confirm("确定要将此数据还原吗？", "提示", {
          type: "info",
        })
        .then((res) => {
          this.Reduct({ id: row.id });
        })
        .catch((err) => {});
    },
    // 还原数据
    Reduct(id) {
      this.loading = true;
      putBack(id)
        .then((res) => {
          this.$message.success(res.msg);
          this.refreshList();
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 点击查看
    editRow(row) {
      this.editForm = row;
      this.openAddNews = true;
    },
    // 修改列表相关状态
    changeStatus(e) {
      this.loading = true;
      const data = {};
      data.id = e.id;
      data.is_show = e.is_show;
      // 更新显示状态
      updateShow(data)
        .then((res) => {
          this.$message.success(res.msg);
          this.refreshList();
        })
        .catch(() => {
          this.refreshList();
        });
    },
    // 切换tab栏
    handleClick(e) {
      if (this.filters[e.name]) {
        this.filterForm = this.filters[e.name];
      } else {
        this.filterForm = {
          start_time: -1, //开始时间
          end_time: -1, //结束时间
          keyword: "", //组织名称
          category_id: -1, // 文章分类
          source_id: -1, // 文章来源
          tab: e.name, //当前筛选tab
          page: 1,
          page_size: this.pageData.per_page,
        };
      }
      this.getNewsList(this.filterForm);
    },
    //表格排序
    sortChange(column) {
      this.filterForm.is_desc = !column.order
        ? -1
        : column.order === "ascending"
        ? 0
        : 1;
      this.filterForm.order_by = column.prop;
      this.getNewsList(this.filterForm);
    },
    // 筛选回调
    ok(e) {
      const pageData = {};
      this.filterForm = { ...this.filterForm, ...e, page: 1 };
      this.getNewsList(this.filterForm);
    },
    // 刷新列表
    refreshList() {
      this.getNewsList({
        page: this.pageData.current_page,
        ...this.filterForm,
      });
    },
    // 分页查询
    changPage(e) {
      this.filterForm.page_size = e.page_size;
      const data = { ...this.filterForm, ...e };
      this.getNewsList(data);
    },
  },
  created() {
    this.getNewsList(this.filterForm); //获取列表数据
  },
  components: {
    listFilter,
    Pagination,
    AddNewsDialog,
  },
};
</script>

<style lang="scss" scoped>
.container {
  .body {
    .err-box {
      ::v-deep.el-input__inner {
        color: red;
      }
    }
  }
}
</style>
