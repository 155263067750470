<!--
 * @Author: dongjia
 * @Date: 2021-09-16 18:11:37
 * @LastEditTime: 2021-09-16 18:31:57
 * @LastEditors: aleaner
 * @Description: 列表过滤窗口组件
 * @FilePath: \gov\components\news-play\ListFilter.vue
 * 怎么可能会有bug！！！
-->
<template>
  <LayoutFilter :onFilter="ok" :onReset="resetForm" :toggleCollapseEnable="false">
    <el-form :model="form" :rules="rules" ref="logFilterForm" label-width="70px" size="medium"
      class="filter-from">

      <!-- 模块&用户名&搜索 -->
      <div class="filter-item">
        <el-form-item label="文章标题：" prop="keyword">
          <el-input v-model="form.keyword" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="来源：" prop="source_id">
          <el-select v-model="source_id" placeholder="请选择">
            <el-option v-for="(item,index) in sourceOptions" :key="index" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分类：" prop="category_id">
          <el-cascader v-model="category_id" :options="categoryOptions" :props="casProps"
            :show-all-levels="false" placeholder="请选择" clearable>
          </el-cascader>
        </el-form-item>
      </div>
      <div class="filter-item">
        <DatePeriodPicker label="联播时间：" start-prop="start_time" :start-time.sync="form.start_time"
          end-prop="end_time" :end-time.sync="form.end_time" />
      </div>
    </el-form>
  </LayoutFilter>
</template>
<script>
import { sourceOption, categoryOption } from "../../api/news-play/index";
import LayoutFilter from "@/base/layout/LayoutFilter";
import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";
export default {
  props: {
    uploadFilter: {
      type: Function,
      required: true,
    },
    filterForm: {
      type: Object,
    },
  },
  data() {
    return {
      rules: {},
      sourceOptions: [],
      categoryOptions: [],
      // 级联属性
      casProps: {
        children: "_children",
        label: "name",
        value: "id",
        expandTrigger: "hover",
        emitPath: false,
        checkStrictly: true,
      },
    };
  },
  methods: {
    //重置表单
    resetForm() {
      this.$refs["logFilterForm"].resetFields();
      this.uploadFilter(this.form);
    },
    //筛选
    ok() {
      this.uploadFilter(this.form);
    },
  },
  created() {
    sourceOption()
      .then((res) => {
        this.sourceOptions = res.data;
      })
      .catch((err) => {});
    categoryOption()
      .then((res) => {
        this.categoryOptions = res.data;
      })
      .catch((err) => {});
  },
  computed: {
    form: {
      get() {
        return this.filterForm;
      },
      set(val) {},
    },
    source_id: {
      get() {
        return this.form.source_id == -1 ? "" : this.form.source_id;
      },
      set(val) {
        this.form.source_id = val;
      },
    },
    category_id: {
      get() {
        return this.form.category_id == -1 ? "" : this.form.category_id;
      },
      set(val) {
        this.form.category_id = val;
      },
    },
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
};
</script>